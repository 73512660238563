@use '@nebular/theme/styles/global/breakpoints';

@mixin ngx-layout() {
  @include breakpoints.media-breakpoint-down(is) {
    .row {
      margin-left: -10px;
      margin-right: -10px;
    }
  }
}
