@use '@progress/kendo-theme-bootstrap/dist/all' as *;

@mixin kendo-custom-button($color, $name) {
  $custom-button-bg: $color !default;
  $custom-button-text: contrast-wcag($custom-button-bg) !default;
  $custom-button-border: $custom-button-bg !default;
  $custom-button-gradient: null !default;

  $custom-button-hovered-bg: try-darken($custom-button-bg, 7.5%) !default;
  $custom-button-hovered-text: contrast-wcag($custom-button-hovered-bg) !default;
  $custom-button-hovered-border: try-darken($custom-button-border, 10%) !default;
  $custom-button-hovered-gradient: null !default;

  $custom-button-pressed-bg: try-darken($custom-button-bg, 10%) !default;
  $custom-button-pressed-text: contrast-wcag($custom-button-pressed-bg) !default;
  $custom-button-pressed-border: try-darken($custom-button-border, 12.5%) !default;
  $custom-button-pressed-gradient: null !default;
  $custom-button-pressed-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.06) !default;

  $custom-button-focused-shadow: 0 0 0 3px rgba($custom-button-border, 0.5) !default;

  .k-button.#{$name} {

    @include fill(
        $custom-button-text,
        $custom-button-bg,
        $custom-button-border,
        $custom-button-gradient
    );

    // Hover state
    &:hover,
    &.k-state-hover {
      @include fill(
          $custom-button-hovered-text,
          $custom-button-hovered-bg,
          $custom-button-hovered-border,
          $custom-button-hovered-gradient
      );
    }

    // Focused state
    &:focus,
    &.k-state-focused {
      box-shadow: $custom-button-focused-shadow;


      &[disabled],
      [disabled] &,
      &.k-state-disabled {
        box-shadow: none;
      }
    }

    // Active state
    &:active,
    &.k-state-active {

      @include fill(
          $custom-button-pressed-text,
          $custom-button-pressed-bg,
          $custom-button-pressed-border,
          $custom-button-pressed-gradient
      );

      box-shadow: $custom-button-pressed-shadow;
    }

    // Selected state
    &.k-state-selected {

      @include fill(
          $custom-button-pressed-text,
          $custom-button-pressed-bg,
          $custom-button-pressed-border,
          $custom-button-pressed-gradient
      );

      box-shadow: $custom-button-pressed-shadow;
    }

  }

  .k-button.#{$name}.k-outline {
    border-color: currentColor;
    color: $custom-button-bg;
    background: none;
    box-shadow: none;

    // Hover state
    &:hover,
    &.k-state-hover {
      @include fill(contrast-wcag($custom-button-bg), $custom-button-bg, $custom-button-bg, none);
    }

    // Focused state
    &:focus,
    &.k-state-focused {
      box-shadow: $custom-button-focused-shadow;
    }

    // Active state
    &:active,
    &.k-state-active {
      @include fill(contrast-wcag($custom-button-bg), $custom-button-bg, $custom-button-bg, none);
      box-shadow: none;
    }

    // Selected state
    &.k-state-selected {
      @include fill(contrast-wcag($custom-button-bg), $custom-button-bg, $custom-button-bg, none);
      box-shadow: none;
    }
  }

  .k-button-flat.#{$name} {

    // Explicitly exclude flat button from button group styling
    &,
    &:hover,
    &.k-state-hover,
    .k-button-group & {
      color: $color;
      background: none;
    }
  }
}

@mixin kendo-custom-buttons() {
  @include kendo-custom-button($danger, 'k-danger');
  @include kendo-custom-button($warning, 'k-warning');
  @include kendo-custom-button($info, 'k-info');
  @include kendo-custom-button($success, 'k-success');
  @include kendo-custom-button($secondary, 'k-secondary');
}
