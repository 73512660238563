$z-index-base-layout: 1040; // layout header (should probably be higher than fixed menu sidebar 999)

// Focus Mode
// 1. Must be higher than the fixed header (see sample.layout.scss)
// 2. Must be the same or lower than kendo-popup z-index (see styles.scss)
$z-index-focus-mode: $z-index-base-layout+1; // grid full-window focus mode

$z-index-overlay: $z-index-focus-mode+1; // cdx popup window
$z-index-popup: $z-index-overlay+1; // kendo dropdown popup (should always be visible at the highest level)

$button-bg: #ffffff;
