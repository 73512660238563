/**
 * Code from https://stackoverflow.com/a/40991531/2557554
 * By CodeBrauer (https://stackoverflow.com/users/1990745/codebrauer)
 * Licensed under CC BY-SA 3.0
 */

/**
 * Usage: <h1 class="loading">Loading</h1>
 */


.loading::after {
  display: inline-block;
  animation: dotty steps(1,end) 1s infinite;
  content: '';
}

@keyframes dotty {
  0%   { content: '   '; }
  25%  { content: '.  '; }
  50%  { content: '.. '; }
  75%  { content: '...'; }
  100% { content: '   '; }
}
